import React from 'react'
import './Galerie.css'
import previewgalerie from '../../assets/galerie/previewgalerie.png'
import nosequipes from '../../assets/galerie/nosequipes.png'
import nosinstallations from '../../assets/galerie/nosinstallations.png'
import nosinterventions from '../../assets/galerie/nosinterventions.png'
import Button from '../../component/button/button'
import PopupDevis from '../../component/popup_devis/popup_devis'
import { useState } from 'react';


/**Nouveaux Images  */

import img1 from '../../assets/galerie/Employe.png'

import img2 from '../../assets/galerie/IMG_0057 copie.png'
import img3 from '../../assets/galerie/IMG_0063 copie.png'
import img4 from '../../assets/galerie/IMG_0072 copie.png'
import img5 from '../../assets/galerie/IMG_0073 (1) copie.png'

import img6 from '../../assets/smith/1.jpg'
import img7 from '../../assets/smith/2.jpg'




const Galerie = () => {
  const [Hide,setHide]=useState('Hide')

  function HidePopup_Devis(e) {
    
    setHide('Hide')
      
  }

  function ShowPopup_Devis(e) {
  
  setHide('Hidepas')
         
  }
 
  return (
    <>
    <div id={Hide} style={{position:'fixed',width:'100%', height:'100%',zIndex:98}}>
      <div id='popup' style={{display:'flex',alignContent:'center',justifyContent:'center',transition: '0.3s',position:'relative'}}>
        <PopupDevis fermeture={HidePopup_Devis}/>
      </div>
    </div>
    
    <div id='Galsect1'>
      
      <div id='Galprev'>
        <div id='Galeft'>
            <h1>
             Découvrez IBS <br/>
              en image
            </h1>
            <div id='Nos'>
              Nos équipes <br/>
              Nos Interventions <br/>
              Nos Installations <br/>
            </div>
            
        </div>
        <div id='Garight'>
          <img src={previewgalerie} alt="preview galerie" />
        </div>
      </div>
      
    </div>
    <div id='Galsect2'>
      <div id='btngals2'>
        <Button text='demandez un devis' onClick={ShowPopup_Devis}/>
      </div>
      <div id='roster'>
        <div>
          <img src={nosequipes} alt="Nos équipes" />
          <span> Nos équipes</span>
        </div>
        <div>
          <img src={nosinterventions} alt="Nos Interventions"  />
          <span> Nos Interventions </span>
        </div>
        <div>
          <img src={nosinstallations} alt="Nos Installations" />
          <span>Nos Installations</span>
        </div>

      </div>
    </div>

    <div id='ImgUpdate'>
      <div id='employe'>
        <img src={img1} alt="Employé du mois" />
        <span>Employé du mois</span>
      </div>
    </div>

    <div id="popover">
      <div>
        <img src={img2} alt="" />
      </div>
      <div>
        <img src={img3} alt="" />
      </div>
      <div>
        <img src={img4} alt="" />
      </div>
      <div>
        <img src={img5} alt="" />
      </div>
    </div>

    <div id='ImgUpdate'>
      <div id='employe'>
        <img src={img6} alt="Employé du mois" />
      </div>
    </div>
    <div id='ImgUpdate'>
      <div id='employe'>
        <img src={img7} alt="Employé du mois" />
      </div>
    </div>
   
    
    

    
    
    </>
    
    
  )
}

export default Galerie