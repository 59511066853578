import React from 'react'
import {useState, useRef,useEffect} from 'react'
import './slide.css'
import banner1 from '../../assets/banner/banner_IBS_site_web_1.png'
import banner2 from '../../assets/banner/banner_IBS_site_web_2.png'
import banner3 from '../../assets/banner/banner_IBS_site_web_3.png'
import banner4 from '../../assets/smith/Logistique 2.jpg' 
const banners=[banner1,banner2,banner3,banner4]
const delay = 3000;

function Slideshow() {
  const [index, setIndex] = useState(0);
  const timeoutRef =useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === banners.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translateX(${-index * 100}%)` }}
      >
        {banners.map((src, index) => (
          <img
            className="slide"
            key={index}
            src={ src }
          alt={index}/>
        ))}
      </div>

      <div className="slideshowDots">
        {banners.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}
export default Slideshow