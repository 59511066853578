import React from 'react'
import './Home.css'
import livreur from '../../assets/livreur.png';
import griphe from '../../assets/griffe.png'
import Button from '../../component/button/button';
import Card from '../../component/card/card'
import Carte from '../../assets/carte-ibs.png'
import donnee_img from '../../databaseimg'
import donnee_texte from '../../databasetexte'
import Article from '../../component/Article/Article'
import PopupDevis from '../../component/popup_devis/popup_devis'
import { useState } from 'react';

import enr from '../../assets/button_methode/enregistrement.png'
import col from '../../assets/button_methode/collecte.png'
import cond from '../../assets/button_methode/conditionnement.png'
import exp from '../../assets/button_methode/expedition.png'
import liv from '../../assets/button_methode/livraison.png'
import sav from '../../assets/button_methode/sav.png'
import Encard from '../../component/encard/encard';

import Welcome from '../../assets/smith/Site web 2.jpg'


const Home = () => {

  const [Hide,setHide]=useState('Hide')

  function HidePopup_Devis(e) {
    
    
    setHide('Hide')
  

  }
  function ShowPopup_Devis(e) {
  
  setHide('Hidepas')
         
  }


 

  return (
    <>
    <div id={Hide} style={{position:'fixed',top:'16vh',width:'100%',zIndex:98}}>
      <div id='popup' style={{display:'flex',alignContent:'center',justifyContent:'center',transition: '0.3s',position:'relative'}}>
        <PopupDevis fermeture={HidePopup_Devis}/>
      </div>
    </div>

    <div id='Home' >
      <div className='Homesection'>
        <img src={Welcome} alt="" style={{width:"100%"}} />
      </div>

      <div id='Homesection1'>
          <div id='Homesection1agauche'>
            <div id='griphe'><img src={griphe} alt="" /></div>
              <div className="container">
                <div className="text">
                Fort de notre <br/>
                service de livraison <br/><span id='text_marked'>Porte à Porte</span><br/>
                nous assurons <br/> le transport express <br/>de colis et courriers <br/>au Congo Brazzaville.
                </div>
                <Button text='Demandez un devis' onClick={ShowPopup_Devis}></Button>
              </div>
           </div>
          
          <div className="Homesection1adroite">
              <img src={livreur} alt="livreur IBS"/>
          </div>

            
      </div>
      

      <div id='Homesection2'>
          <div id='flexeur_container'>
            <div id='flexeur'>
              <Card  imgsrc={donnee_img.carre.transport} textact='Transport et livraison express'></Card>
              <Card imgsrc={donnee_img.carre.logistique} textact='Fret et logistique'></Card>
              <Card imgsrc={donnee_img.carre.demenagement} textact='Bâtiments'></Card>
              <Card imgsrc={donnee_img.carre.livraison} textact='Services'></Card>
            </div>
            <div id='col'>
              <div id="text">
                <h1>
                    Zone d'intervention 
                </h1>
                <p>
                Profitez de la couverture 
                nationale, sous régionale 
                d’IBS <span>(Brazzaville, Pointe-Noire, Dolisie, Impfondo,
                nkayi, Oyo, Ouesso, la RDC, le Cameroun, le Gabon etc.)</span> et élargie à la Chine, Dubaï et la Turquie
                pour tous vos besoins en transport, logistique, fret, bâtiment et autres services
                sécurisés et adaptés.
                </p>

                <Button text='Demandez un devis'onClick={ShowPopup_Devis} />
              </div>
              <div id='carte'>
                <img src={Carte} alt="" />
              </div>
          </div>
          </div>
      </div>


      <div id='Homesection3' >
        <div className="row1">
          <div className='div36'>
            <p id='objectif'>
              Afin de répondre à vos attentes,IBS propose un 
              service express efficace, sécure,
              et à moindre coût pour le transport et la livraison des colis et courriers au Congo Brazzaville.
            </p>
          </div>
          <Encard img={donnee_img.icons.ico_transport} titre='transport' paragraph={donnee_texte.textcarre.transport}/>
          <Encard img={donnee_img.icons.ico_fret} titre='fret' paragraph={donnee_texte.textcarre.fret}/>
        </div>
        <div className="row2">
          <div className='div36'>  
            <p id='one'>
                  
            Au service de sa clientèle depuis plus de 10 ans, Inter-logistique Bâtiments et Services (IBS) s’impose comme l’acteur Congolais de référence et le partenaire idéal des particuliers, des entreprises ou des entités publiques qui profitent de son expertise, de son professionnalisme et de la qualité de ses réalisations.


            </p>
            <p id='two'>
            Parce que chaque service est unique, IBS ajuste son offre à vos besoins avec pour fil conducteur, des tarifs imbattables, le respect des délais et la sécurité. Notre catalogue express comprend le transport et la livraison au pas de porte de toutes vos marchandises : Colis standards, produits sensibles, denrées alimentaires, matériels, conteneurs, etc. 
            </p>
          </div>
          <Encard img={donnee_img.icons.ico_logistique} titre='Logistique' paragraph={donnee_texte.textcarre.logistique}/>
          <Encard img={donnee_img.icons.ico_demenagement} titre='Déménagement' paragraph={donnee_texte.textcarre.demenagement}/>
        </div>
        <div className="row3">
        <div id='row3img'>
  <img src={donnee_img.bg_image} alt='bg livreur' className='bg' />
          
        </div>
          <Encard img={donnee_img.icons.ico_livraison} titre='Livraison express' paragraph={donnee_texte.textcarre.livraison_express}/>
        </div>
      </div>
         
      <div id='Homesection4' className='Section4'>         
        <div className='cadrebleu'>
          <span >Qui sommes nous?</span>           
          <span> Nous sommes express!</span>
          <span className='send'> Envoyer et recevez vos colis <br/> à domicile ou en main propre</span>
        </div>
        
      </div>


      <div id='Homesection5'>
        <div className="item1">
          <div id='Ibs'>
              Avec <span id='IBS'>IBS </span><br/>
              la livraison n’a plus 
              de secret pour vous,
              expédiez et recevez vos 
              marchandises, matériels, couriers 
              et objets précieux dans
              toutes les villes du Congo
          </div>
  
        </div>
        <div className='item2'>
          <img src={donnee_img.section5img} alt="" />
        </div>
      </div>
      

      <div id='Homesection6'>
          <span>La methode <span>Ibs-Express</span></span>
          <div className="rowimg1">
            <img src={enr} alt="evaluation" />
            <img src={cond} alt="ramassage" />
            <img src={col} alt="conditionnement" />
            <img src={exp} alt="expedition" />
          </div>
          <div className="rowimg2">
            <img src={liv} alt="livraison" />
            <img src={sav} alt="sav" />
          </div>
      </div>


      <div id='Homesection7'>
        <div id='blocbleu'>
          <p>
          IBS est une société de droit Congolais avec près de 10 ans 
          d’expérience dans les domaines du Transport, de la Logistique, 
          du Fret, Colis et courriers express, du Bâtiment et des services multiformes.
          </p>
          <p>
          Reconnu comme un intermédiaire sûr et fiable, IBS veille à répondre avec efficacité aux demandes
          de sa clientèle et s’assure de la satisfaire en optimisant la méthode, les coûts et les délais.
          </p>
        </div>
      </div>   

            
      <div id='Homesection8' >
        <div id='Homesection8_center' className="kati">
          <h1> 
            Nos Dernières réalisations 
          </h1>
          <p>
            Ici un condensé des dernières réalisations d’IBS, témoignage de notre efficacité.
          </p>
          <div className="flexrow">

            <Article to='./ArticleHelios' AIS={donnee_img.image4} paragraphArticle={donnee_texte.Articles.article4}/>  
            <Article to='./Articles' AIS={donnee_img.image2} paragraphArticle={donnee_texte.Articles.article2}/>
            <Article to='./Articles' AIS={donnee_img.image1} paragraphArticle={donnee_texte.Articles.article1}/>
            <Article to='./Articles' AIS={donnee_img.image3} paragraphArticle={donnee_texte.Articles.article3}/>
           
          </div>
        </div>      
      </div>


      <div id='Homesection9'>
        <h1>
          Ils nous font confiance
        </h1>
        <p>
        Nous sommes à leur service !
        </p>
        <div className='flexpartenaire'>
          <img src={donnee_img.partenaires} alt="" />
          
        </div>
      </div>
  
    </div>
    </>
    
  )
}

export default Home